import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  BooleanInput,
  DateField,
  ShowButton
} from 'react-admin';


const LogFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="project" reference="projects" allowEmpty alwaysOn>
      <SelectInput optionText="project" />
    </ReferenceInput>
    <BooleanInput label="Enabled" source="enabled" defaultValue="true" />
  </Filter>
);

const LogPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const LogList = (props) => (
  <List
    {...props}
    filters={<LogFilter />}
    pagination={<LogPagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
	  <DateField source="created" showTime />
      <ReferenceField reference="projects" source="project">
		 <TextField source="project" />
	  </ReferenceField>
	  <TextField source="identity" />
	  <TextField source="version" />
	  <TextField source="type" />
	  <TextField source="message" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const LogShow = (props) => (
	<Show {...props}>
		<SimpleShowLayout>
		<TextField source="id" />
		<DateField source="created" showTime />
		<ReferenceField reference="projects" source="project">
			<TextField source="project" />
		</ReferenceField>
		<TextField source="identity" />
	  <TextField source="version" />
	  <TextField source="type" />
	  <TextField source="message" />
	  <TextField source="data" />	  
		</SimpleShowLayout>
	</Show>
);