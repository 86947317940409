import React from 'react';

class Category extends React.Component {
	
		render() {
			return (
				<div style={{
					margin: '20px 0px 0px 20px'
				}}>
					<span className="menu-header">{this.props.title}</span>
					{this.props.children}
				</div>
			);
		}
		
}

export default Category