import React from 'react';
import { Admin, Resource, fetchUtils, DashboardMenuItem, Menu, MenuItemLink, Layout,
	Authenticated } from 'react-admin';
import { Route } from 'react-router-dom';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import jsonServerProvider from 'ra-data-json-server';
import englishMessages from 'ra-language-english';
import authProvider from './authProvider';
import Settings from './settings';
import LoginPage from './customLogin';
import Category from './category';
import { defaultTheme, CustomRoutes } from 'react-admin';
import { useGetList } from 'react-admin';
import Badge from '@mui/material/Badge';
import { Count } from 'react-admin';

// CSS:
import './foradmin-base.css'

// Icons:
import AccountIcon from '@mui/icons-material/AccountBox';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BugReportIcon from '@mui/icons-material/BugReport';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import GroupIcon from '@mui/icons-material/Group';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachFile from '@mui/icons-material/AttachFile';
import InfoIcon from '@mui/icons-material/Info';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';

// Pages:
import Documentation from './pages/documentation'
import Dashboard from './pages/dashboard'
import Requests from './pages/requests'

// Resources:
import {
  UserList, UserCreate, UserEdit, UserShow,
} from './pages/users';
import {
  ProjectList, ProjectCreate, ProjectEdit, ProjectShow,
} from './pages/projects';
import {
  ApiKeyList, ApiKeyCreate, ApiKeyEdit, ApiKeyShow,
} from './pages/apiKeys';
import {
  CrashList, CrashShow,
} from './pages/crashes';
import {
  LogList, LogShow,
} from './pages/logs';
import {
  CredentialList, CredentialCreate, CredentialEdit, CredentialShow,
} from './pages/credentials';
import {
  ClientList, ClientCreate, ClientEdit, ClientShow,
} from './pages/clients';

const messages = {
/*  cs: czechMessages,*/
  en: englishMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'en');

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = jsonServerProvider(Settings.SERVER, httpClient);

const theme = {
    ...defaultTheme,
    palette: {
        mode: 'dark', // Switching the dark mode on is a single property value change.
    },
};

const MySidebar = props => {
	const { data, total, isLoading, error } = useGetList(
		'requests',
		{ 
			pagination: { page: 1, perPage: 1 },
			sort:{ field: 'id', order: 'DESC' }
		}
	);	
    return (
		<Menu /*{...props}*/
		sx={{
			'&.RaMenu-closed': {
				marginLeft: "-20px",
				marginRight: "20px",
			},
			'.menu-header': {
				overflow: "visible"
			},
			'&.RaMenu-closed .menu-header': {
				height: 0,
				overflow: "hidden"
			}
		}}
		>
				<Menu.DashboardItem sx={{
					margin: '20px 0px 0px 20px'					
				}} to="/" />
				<Menu.Item sx={{
					margin: '0px 0px 0px 20px'					
				}} to="/requests" primaryText={
					(!isLoading && total > 0) &&
					<Badge badgeContent={<Count resource="requests" />} color="error">
						Requests
					</Badge>
					|| <>Requests</>
				} leftIcon={<ChecklistRtlIcon />} />
				
				<Category title="Analysis">
					<Menu.ResourceItem name="crashes" primaryText="Crash Reports" leftIcon={<BugReportIcon />}/>
					<Menu.ResourceItem name="logs" primaryText="Logs" leftIcon={<EventNoteIcon />}/>
				</Category>
				
				<Category title="Licencing">
					<Menu.ResourceItem name="credentials" primaryText="Credentials" leftIcon={<GroupIcon />}/>
					<Menu.ResourceItem name="licenses" primaryText="Licenses" leftIcon={<AttachFile />}/>
				</Category>
				
				<Category title="Settings">
					<Menu.ResourceItem name="projects" primaryText="Projects" leftIcon={<FeaturedPlayListIcon />}/>
					<Menu.ResourceItem name="clients" primaryText="Clients" leftIcon={<ContactMailIcon />}/>
					<Menu.ResourceItem name="apikeys" primaryText="API Keys" leftIcon={<VpnKeyIcon />}/>
					<Menu.ResourceItem name="users" primaryText="Administrators" leftIcon={<SettingsIcon />}/>
				</Category>
				
				<Category title="Other tools">
					<Menu.Item to="/documentation" primaryText="Documentation" leftIcon={<InfoIcon />}/>
				</Category>
		</Menu>
    );
};

const MyLayout = props => <Layout {...props} sidebar={MySidebar} />

const App = () => (
  <Admin
	theme={theme}
	layout={MyLayout}
	disableTelemetry
	dataProvider={dataProvider}
	i18nProvider={i18nProvider}
	authProvider={authProvider}
	loginPage={LoginPage}
	>
	
	<CustomRoutes>
		<Route exact path="/" element={<Authenticated><Dashboard /></Authenticated>} />
		<Route path="/documentation" element={<Authenticated><Documentation /></Authenticated>} />
		<Route path="/requests" element={<Authenticated><Requests /></Authenticated>} />
	</CustomRoutes>
	
	<Resource name="crashes" options={{ label : 'Crash Reports' }} icon={BugReportIcon}
		list={CrashList} show={CrashShow} />
	<Resource name="logs" options={{ label : 'Logs' }} icon={EventNoteIcon}
		list={LogList} show={LogShow} />	
		
	<Resource name="credentials" options={{ label : 'Credentials' }} icon={GroupIcon}
		list={CredentialList} edit={CredentialEdit} create={CredentialCreate} show={CredentialShow} />	
	<Resource name="licenses" options={{ label : 'Licenses' }} icon={AttachFile}
		list={CredentialList} />	
		
	<Resource name="projects" options={{ label : 'Projects' }} icon={FeaturedPlayListIcon}
		list={ProjectList} edit={ProjectEdit} create={ProjectCreate} show={ProjectShow}  />
	<Resource name="clients" options={{ label : 'Clients' }} icon={ContactMailIcon}
		list={ClientList} edit={ClientEdit} create={ClientCreate} show={ClientShow}  />
	<Resource name="apikeys" options={{ label : 'API Keys' }} icon={VpnKeyIcon}
		list={ApiKeyList} edit={ApiKeyEdit} create={ApiKeyCreate} show={ApiKeyShow} />	
	<Resource name="users" options={{ label : 'Users' }} icon={AccountIcon}
		list={UserList} edit={UserEdit} create={UserCreate} show={UserShow} />
		
	<Resource name="requests" options={{ label : 'Pending requests' }} icon={ChecklistRtlIcon} />
	
  </Admin>
);

export default App;
