import React from 'react';
import { Login, LoginForm } from 'react-admin';
//import { withStyles } from '@material-ui/core/styles';
//import { styled, createTheme, ThemeProvider } from '@mui/system';
import logo from './logo.png';

const styles = ({
    main: { background: '#1a0549 linear-gradient(to right, #221c47, #003061);' },
    avatar: {
        background: `url(${logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
		backgroundPosition: 'center',
        height: 80,
		margin: 30
    },
    icon: { 
		display: 'none'
	},
});

const CustomLoginForm = LoginForm; /*({
    button: { background: '#F15922' },
})(LoginForm);*/

const CustomLoginPage = props => (
		<div>
			<Login
				loginForm={<CustomLoginForm />}
				sx={{
					'.RaLogin-avatar' : {
						background: `url(${logo})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'contain',
						backgroundPosition: 'center',
						height: '80px',
						margin: '50px 0px 30px',
						width: '100%'
					},
					'.RaLogin-icon' : {
						display: 'none'
					}
				}}
				{...props}
			/>
		</div>
);

export default CustomLoginPage;