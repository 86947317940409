import React from 'react';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Title } from 'react-admin';
import { List, Datagrid, TextField, ResourceContextProvider,
Button, ListContext, WithListContext, Confirm, useDelete } from 'react-admin';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import sw from '../sw.jpg';

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Requests = () => {	

	const [approveOpen, setApproveOpen] = useState(false);
	const [dismissOpen, setDismissOpen] = useState(false);
	const [editedRec, setEditedRec] = useState(null);
	
	const [remove, { isLoading }] = useDelete(
        'requests',
        { id: editedRec?.id }
    );
	
	return (<><List resource="requests" sx={{ ".RaList-content" : { background : 'none' } }}>	
		<WithListContext render={({data}) => {
			const [expanded, setExpanded] = React.useState(null);
			const handleExpandClick = (x) => {
				if (expanded == x)
					setExpanded(null);
				else
					setExpanded(x);
			  };
			return (
			<Stack direction="row" spacing={2}>
			{ data?.map(record => { 
				let meta = "";
				try { meta = JSON.stringify(JSON.parse(record.meta), null, 2); } catch { meta = record.meta; }
				return <Card key={record.id} sx={{ minWidth: 345, maxWidth: 345, alignSelf: 'flex-start' }}>
			
				<CardMedia				
					sx={{ height: 140 }}
					image={sw}
					title="green iguana"
				/>
				<CardContent>
					<Typography variant="h5" component="div" sx={{marginBottom: "20px"}}>
						Type: {record.type}
					</Typography>
					<Typography variant="body2" color="text.primary">
						ID: {record.name}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						IP: {record.ip}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						Mac: {record.mac}
					</Typography>
					
					<Collapse in={expanded == record} timeout="auto" unmountOnExit>
					<Typography variant="body2" color="text.secondary" sx={{fontFamily: 'monospace', marginTop: "30px"}}>
					<pre>
						{ meta }
					</pre>
					</Typography>
				</Collapse>
				</CardContent>
				<CardActions disableSpacing>
					<Button label="Approve" variant="contained" startIcon={<CheckIcon />} onClick={() => {
						setEditedRec(record);
						setApproveOpen(true);
					}} />&nbsp;
					<Button label="Dismiss" variant="outlined"  startIcon={<CloseIcon />} onClick={() => {
						setEditedRec(record);
						setDismissOpen(true);
					}} />
					<ExpandMore
					  expand={expanded == record}
					  onClick={() => { handleExpandClick(record); }}
					  aria-expanded={expanded}
					  aria-label="show more"
					>
						<ExpandMoreIcon />
					</ExpandMore>
				</CardActions>
			</Card>}
			)}
			</Stack>
		)} }>
		</WithListContext>
	</List>
	<Confirm
		isOpen={approveOpen}
		title={"Do you wish to approve " + editedRec?.name + "?"}
		content={ <><p>Type: {editedRec?.type}</p><p>ID: {editedRec?.name}</p><p>IP: {editedRec?.ip}</p><p>Mac: {editedRec?.mac}</p></> }
		onConfirm={() => { alert('confirmed...'); setApproveOpen(false); }}
		onClose={() => { setApproveOpen(false); }}
		confirm="Approve"
	/>
	<Confirm
		isOpen={dismissOpen}
		title={"Do you wish to dismiss " + editedRec?.name + "?"}
		content={ <><p>Type: {editedRec?.type}</p><p>ID: {editedRec?.name}</p><p>IP: {editedRec?.ip}</p><p>Mac: {editedRec?.mac}</p></> }
		onConfirm={() => { remove(); setDismissOpen(false); }}
		onClose={() => { setDismissOpen(false); }}
		confirm="Dismiss"
	/>
	</>
)}; 

export default Requests;